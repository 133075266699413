import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <div className="container-theme">
      <section className="container-fluid">
        <SEO title="404: Not found" />
        <h1>Page Not Found</h1>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
